<template>
<div>
    <h1>Game</h1>
    <b-form-checkbox v-model="readonly">Readonly</b-form-checkbox>
    <iframe :src="url" height="600" width="800" frameborder="0" :class="{readonly}"/>
</div>
</template>

<script>
export default {
    data() {
        return {
            readonly: false,
            url: "https://0d54c8168a39.ngrok.io",
        };
    }
}
</script>

<style lang="scss" scoped>

.readonly {
    pointer-events: none;
}

</style>
